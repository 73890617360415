// extracted by mini-css-extract-plugin
export var feedback = "form-feedback-module--feedback--3zS4r";
export var feedbackTitle = "form-feedback-module--feedbackTitle--2e_9I";
export var form = "form-feedback-module--form--wRxt-";
export var input = "form-feedback-module--input--2xLW1";
export var inputInActive = "form-feedback-module--inputInActive--2lBpm";
export var inputActive = "form-feedback-module--inputActive--3ZYxG";
export var agree = "form-feedback-module--agree--2GacD";
export var textarea = "form-feedback-module--textarea--fd2K7";
export var textareaActive = "form-feedback-module--textareaActive--28Hro";
export var textareaInActive = "form-feedback-module--textareaInActive--2Chcb";
export var button = "form-feedback-module--button--3UD5p";